const landingES = {
  hero: {
    heading: {
      title: "Gana una tarifa horaria mayor sin esfuerzo",
      hilight: "que tu abogado",
    },
    trust: {
      heading: {
        title: "Únete a más de 1200 Savers felices 🚀",
        hilight: "igual que ellos",
      },
      reviews: [
        {
          name: "Joe D.",
          title: "🇦🇲",
          content:
            "¡Social Saver lo hizo tan fácil! Estoy ahorrando en comisiones y ganando cada semana.",
          image: "https://placehold.co/120",
        },
        {
          name: "François E.",
          title: "🇧🇸",
          content:
            "¡Increíble! ¡No trabajé ni 20 minutos y gané un bono de 300$!",
          image: "https://placehold.co/120",
        },
        {
          name: "Sarah F.",
          title: "🇦🇷",
          content:
            "Los mejores ingresos extra que he encontrado. ¡Ahora quiero conseguir más y más afiliados!",
          image: "https://placehold.co/120",
        },
      ],
    },
  },

  whatisit: {
    heading: {
      title: "🌟 ¿Qué obtienes con Social Saver?",
      hilight: "Rápido, Fácil, Rentable",
    },
    pros: [
      "🤑 280 $ / hora* (¡Sí, más que tu abogado!)",
      "🧑‍💻 5 pasos simples, solo 12 minutos en total",
      "🔄 Límite semanal de 280 € – ¡Reserva tu lugar hoy!",
    ],
    bonus: {
      affiliation: "Gana 7,5$ por cada transferencia que refieras",
      punchline:
        "Quizás hasta tu amigo te invite una copa para darte las gracias",
    },
  },
  howto: {
    heading: {
      title: "🎉 Comienza en solo 5 pasos simples",
      hilight: "Menos de 12 minutos",
    },
    steps: [
      {
        content: "📝 Regístrate en Social Saver",
        suffix: "~2 minutos, 5 clics",
      },
      {
        content: "📲 Comparte tu Revtag para recibir pagos",
        suffix: "~2 minutos, 5 clics",
      },
      {
        content: "💰 Deposita USDT en tu cuenta",
        suffix: "~2 minutos, 4 clics",
      },
      {
        content: "💱 Realiza tu pedido",
        suffix: "~1 minuto, 2 clics",
      },
      {
        content: "✅ Confirma que has recibido 280 € y libera el USDT",
        suffix: "1 minuto, 3 clics",
      },
    ],
    outro: {
      question: "¿Y luego?",
      choice:
        "La elección es tuya: Invierte en otra criptomoneda 😎 - Sal a cenar 😋 - O ve al gimnasio 💪.",
      share: "(¡Y no olvides compartir una foto con nosotros 🫶!)",
    },
  },
  statistics: {
    heading: {
      title: "❤️ Gracias a nuestros primeros Savers",
      hilight: "Que nos trajeron aquí 📈",
    },
    stats: [
      {
        icon: "😎",
        title: "Happy Savers",
        value: 1200,
        counterSuffix: "+",
      },
      {
        icon: "€",
        title: "Paid Out",
        value: 2_100_000,
        counterSuffix: "+",
      },
      {
        icon: "＄",
        title: "Released",
        value: 1_900_000,
        counterSuffix: "+",
      },
      {
        icon: "🕐",
        title: "Average paid time",
        value: 24,
        counterSuffix: "min.",
      },
      // `### 1,245 ###
      // Happy Savers`,
      // `### €2,045,800 ###
      // Paid Out`,
      // `### 1,996,000+ ###
      // USDT Released`,
      // `### 24 minutes ###
      // Average Transfer Time`,
    ],
  },
  ctasaving: {
    heading: {
      title: "🔥 ¿Sigues pagando comisiones cuando puedes ganar?",
      hilight: "¡Comienza a ahorrar hoy!",
    },
  },
  cookbook: {
    heading: {
      title: "🧩 La receta secreta",
      hilight: "detrás de la magia de Social Saver",
    },
    intro:
      "Te facilitamos el obtener ganancias con un sistema simple y eficiente",
    ingredients: [
      {
        content:
          "👥 Agrupamos múltiples transacciones pequeñas de USDT de usuarios.",
        suffix: "(como tú antes)",
      },
      {
        content: "📦 Estas transacciones se combinan en un lote de 280 €",
        suffix: "reduciendo las comisiones de transacción",
      },
      {
        content:
          "🔓 Luego, liberamos el USDT directamente a la billetera on-chain del pagador.",
        suffix: "reduciendo aún más las comisiones",
      },
    ],
    outro: {
      explaination: "¡Así es como mantienes más dinero en tu bolsillo! 🤑",
    },
  },
  estimation: {
    heading: {
      title: "💸 Echemos un vistazo a tus ganancias mensuales",
      hilight:
        "¿y qué podría hacer un extra de 470 $ para tus planes navideños?",
    },
    earnings: {
      caption: "",
      opportunity: {
        placeholders: [
          "¿A cuántos Savers podrías ayudar?",
          "¿8? (+ 440$/mes)",
          "¿12? (+ 560$/mes)",
          "¿18? (+ 740$/mes)",
          "O incluso más...",
          "Or even more...",
          "¿30? (+ 1100$/mes)",
        ],
      },
      headers: {
        scenario: "Escenario",
        earning: "Ganancias mensuales",
      },
      data: [
        ["Tú <br /><small>(solo)</small>", "$100"],
        ["Tú <br /><small>+ Tu pareja</small>", "$230"],
        ["Tu pareja <br /><small>+ una pareja amiga</small>", "$290"],
        // ["Tu pareja <br /><small>+ dos parejas amigas</small>", "$350"],
        ["Tu pareja <br /><small>+ cuatro parejas amigas</small>", "$470"],
      ],
    },

    outro: {
      title: "🏁 ¿Listo para comenzar? No te lo pierdas",
      hilight:
        "Haz clic aquí 👇 para comenzar y referir a tu pareja antes de que lo hagan tus amigos 😎!",
      cta: "CTA TEXT HERE",
    },
  },
  faq: {
    heading: {
      title: "FAQ",
      hilight: "Preguntas Frecuentes",
    },
    items: [
      {
        question: "¿Qué es un Revtag?",
        answer:
          "Un Revtag es tu identificador único en Social Saver, como un nombre de usuario, que permite a otros usuarios enviarte pagos directamente. Piensa en él como tu identificador personal de pagos.",
      },
      {
        question: "¿Cómo abro una cuenta en Revolut?",
        answer:
          "Para abrir una cuenta en Revolut, descarga la app Revolut desde la App Store o Google Play. Sigue los pasos de registro y tendrás tu cuenta lista en unos minutos.",
      },
      {
        question: "¿Cómo compro USDT?",
        answer:
          "Para comprar USDT, puedes usar plataformas populares como Binance o Coinbase. Solo crea una cuenta, completa las verificaciones necesarias y busca USDT en la app para hacer tu compra.",
      },
      {
        question: "¿Qué criptomonedas puedo vender?",
        answer:
          "Actualmente, Social Saver admite principalmente transacciones con USDT. Otras criptomonedas podrían añadirse en el futuro.",
      },
      {
        question: "¿Por qué no se aceptan más criptomonedas?",
        answer:
          "La mayoría de nuestros usuarios prefieren vender stablecoins tras obtener ganancias. Dado que las stablecoins tienen un valor estable, es más fácil para ellos retirar sin preocuparse por la volatilidad. Por eso nuestra plataforma se centra en stablecoins, haciendo el proceso más seguro y confiable para todos.",
      },
      {
        question: "¿Cuáles son los límites?",
        answer:
          "Social Saver limita las transferencias a 280 € por usuario cada semana. Esto nos permite mantener las transacciones fluidas y asegurar un servicio de calidad para todos.",
      },
      {
        question: "¿Cómo funciona?",
        answer:
          "¡Es simple! Social Saver agrupa transacciones pequeñas de USDT en un lote de 280 €, lo que ayuda a reducir las comisiones. Tras depositar USDT, Social Saver transfiere 280 € a tu Revtag, y luego tú liberas el USDT a la billetera del comprador.",
      },
      {
        question: "¿Puedo cancelar un pedido?",
        answer:
          "Sí, puedes cancelar un pedido siempre y cuando no se haya completado. Ve a la configuración de tu cuenta o historial de pedidos, selecciona el pedido y elige Cancelar.",
      },
      {
        question: "¿Puedo retirar fondos?",
        answer:
          "Claro. Puedes retirar tus fondos a cualquier billetera o cuenta bancaria vinculada en cualquier momento. Consulta con tu proveedor de billetera sobre tiempos específicos de retiro o comisiones.",
      },
      {
        question: "¿Tienes más preguntas?",
        answer:
          "No dudes en contactarnos a contact@social-saver.com para obtener más ayuda.",
      },
    ],
  },

  testimonial: {
    heading: {
      title: "🌐 Como lo hacen más de 1200 Savers",
      hilight: "Pon la comisión de TX en tu bolsillo",
    },
    reviews: [
      {
        name: "Michael R.",
        title: "🇨🇦",
        content:
          "Social Saver ha sido un cambio total para mis ingresos: ganar es fácil y la velocidad de transacción es impresionante. Lo único que me gustaría es que el pedido de 280 se estableciera automáticamente cada semana. ¡Esa función lo haría insuperable!",
        image: "https://placehold.co/120",
      },
      {
        name: "Marie P.",
        title: "🇬🇷",
        content:
          "¡Social Saver es súper fácil de usar! Hice mi primera transferencia en menos de 15 minutos. Ahora, estoy ahorrando en comisiones y ganando dinero extra cada semana solo compartiéndolo con amigos.",
        image: "https://placehold.co/120",
      },
      {
        name: "Jamal T.",
        title: "🇬🇧",
        content:
          "¡No esperaba ganar tanto! Cada referencia me da 10$ adicionales, y los pasos son tan simples que no tuve problemas al comenzar. ¡Los mejores ingresos extra que he encontrado!",
        image: "https://placehold.co/120",
      },
      {
        name: "Sarah L.",
        title: "🇺🇸",
        content:
          "La app es muy intuitiva. Apenas tuve que aprender nada: solo unos pocos clics y estoy ganando dinero. Con unos cuantos amigos a bordo, mis ganancias mensuales siguen aumentando.",
        image: "https://placehold.co/120",
      },
      {
        name: "Lucas W.",
        title: "🇩🇰",
        content:
          "Social Saver convirtió el ahorro en una fuente de ingresos pasiva. ¡El bono de afiliado es un gran extra! Fácil de usar, rápido de configurar, y el dinero extra lo vale.",
        image: "https://placehold.co/120",
      },
    ],
    outro: {
      title: "🕢 ¿Sigues leyendo?",
      content:
        "Procesamos **1 transferencia por semana** por usuario, así que cuanto antes empieces, antes entrarás en línea para la siguiente.",
      cta: "CTA TEXT HERE",
    },
  },
};

export default landingES;
