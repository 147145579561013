const landingEN = {
  hero: {
    heading: {
      title: "Effortlessly make an higher hourly rate",
      hilight: "Than does your lawyer",
    },
    trust: {
      heading: {
        title: "Join 1200+ happy Savers 🚀",
        hilight: "Just like them",
      },
      reviews: [
        {
          name: "Joe D.",
          title: "🇦🇲",
          content:
            "Social Saver made it so simple! I’m saving on fees and earning every week.",
          image: "https://placehold.co/120",
        },
        {
          name: "Fançois E.",
          title: "🇧🇸",
          content:
            "Unbelievable !! I didn't work 20 minutes and i grab a 300$ bonus inconme !",
          image: "https://placehold.co/120",
        },
        {
          name: "Sarah F.",
          title: "🇦🇷",
          content:
            "“Best side income I’ve ever found. Now, I look forward to get more and more affiliates!",
          image: "https://placehold.co/120",
        },
      ],
    },
  },

  whatisit: {
    heading: {
      title: "🌟 What will you get using Social Saver ?",
      hilight: "Quick, Easy, Profitable",
    },
    pros: [
      "🤑 280 $ / hour* (Yes, more than your lawyer makes!)",
      "🧑‍💻 5 simple steps, just 12 minutes total",
      "🔄 Weekly receiving limit of €280 – So, claim your spot today!",
    ],
    bonus: {
      affiliation: "Earn 7.5$ for every friend’s transfer you refered",
      punchline: "Your friend might even buy you a drink to say thanks",
    },
  },
  howto: {
    heading: {
      title: "🎉 Get Started in Just 5 Simple Steps",
      hilight: "Less than 12 minutes",
    },
    steps: [
      {
        content: "📝 Register on Social Saver",
        suffix: "~2 minutes, 5 clicks",
      },
      {
        content: "📲 Share your Revtag to receive payments",
        suffix: "~2 minutes, 5 clicks",
      },
      {
        content: "💰 Deposit USDT into your account",
        suffix: "~2 minutes, 4 clicks",
      },
      {
        content: "💱 Place your order",
        suffix: "~1 minute, 2 clicks",
      },
      {
        content: "✅ Confirm you received €280 and release the USDT",
        suffix: "1 minute, 3 clicks",
      },
    ],
    outro: {
      question: "Then what?",
      choice:
        "The choice is yours : Invest in another crypto 😎 - Go out to dine 😋 - Or get a gym time 💪.",
      share: "(And hey ! Don’t forget to share a pic with us 🫶)",
    },
  },
  statistics: {
    heading: {
      title: "❤️ Thanks to our early Savers",
      hilight: "Wich brought us here 📈",
    },
    stats: [
      {
        icon: "😎",
        title: "Happy Savers",
        value: 1200,
        counterSuffix: "+",
      },
      {
        icon: "€",
        title: "Paid Out",
        value: 2_100_000,
        counterSuffix: "+",
      },
      {
        icon: "＄",
        title: "Released",
        value: 1_900_000,
        counterSuffix: "+",
      },
      {
        icon: "🕐",
        title: "Average paid time",
        value: 24,
        counterSuffix: "min.",
      },
      // `### 1,245 ###
      // Happy Savers`,
      // `### €2,045,800 ###
      // Paid Out`,
      // `### 1,996,000+ ###
      // USDT Released`,
      // `### 24 minutes ###
      // Average Transfer Time`,
    ],
  },
  ctasaving: {
    heading: {
      title: "🔥 Still paying fees when you can earn ?",
      hilight: "Start Saving Today !",
    },
  },
  cookbook: {
    heading: {
      title: "🧩 The secret cookbook",
      hilight: "Behind the Social Saver’s magic",
    },
    intro: "We make it easy for you to profit with a simple, efficient system",
    ingredients: [
      {
        content: "👥 We group multiple small USDT transactions from users.",
        suffix: "(like you before)",
      },
      {
        content: "📦 These transactions are combined into a single €280 batch",
        suffix: "lowering transaction fees",
      },
      {
        content:
          "🔓 Then, we release the USDT directly to the payer’s onchain wallet.",
        suffix: "lowering even more transaction fees",
      },
    ],
    outro: {
      explaination: "That’s how you keep more money in your pocket ! 🤑",
    },
  },
  estimation: {
    heading: {
      title: "💸 Let's look what are going to be your monthly earnings",
      hilight: "and what an extra $ 470 could do for your christmas plans ?",
    },
    earnings: {
      caption: "",
      opportunity: {
        placeholders: [
          "How many Savers could you help ?",
          "8 ? (+ $440/m)",
          "12 ? (+ $560/m)",
          "18 ? (+ $740/m)",
          "Or even more...",
          "30 ? (+ $1100/m)",
        ],
      },
      headers: {
        scenario: "Scenario",
        earning: "Monthly Earnings",
      },
      data: [
        ["You <br /><small>(alone)</small>", "$100"],
        ["You <br /><small>+ Your partner</small>", "$230"],
        ["Your couple <br /><small>+ 1 friends couple</small>", "$290"],
        // ["Your couple <br /><small>+ 2 friends couple</small>", "$350"],
        ["Your couple <br /><small>+ 4 friends couple</small>", "$470"],
      ],
    },

    outro: {
      title: "🏁 Ready to start ? Don’t Miss Out",
      hilight:
        "Click here 👇 to get started and refer your spouse before your friends does 😎!",
      cta: "CTA TEXT HERE",
    },
  },
  faq: {
    heading: {
      title: "FAQ",
      hilight: "Frequently Asked Questions",
    },
    items: [
      {
        question: "What is a Revtag",
        answer:
          "A Revtag is your unique identifier on Social Saver, like a username, that allows other users to send payments directly to your account. Think of it as your personal payment handle.",
      },
      {
        question: "How do I open a Revolut account",
        answer:
          "To open a Revolut account, download the Revolut app from the App Store or Google Play. Follow the registration steps, and you’ll be set up with an account in just a few minutes",
      },
      {
        question: "How do I buy USDT",
        answer:
          "To buy USDT, you can use popular exchanges like Binance** or Coinbase. Simply create an account, complete any necessary verifications, then search for USDT in the app to make your purchase.",
      },
      {
        question: "What cryptocurrencies can I sell ",
        answer:
          "Currently, Social Saver primarily supports USDT transactions. Other cryptocurrencies may be added in the future.",
      },
      {
        question: "Why aren’t more cryptocurrencies accepted",
        answer:
          "Most of our users prefer to sell stablecoins after taking profits. Since stablecoins offer a predictable value, it’s easier for them to cash out without worrying about volatility. That’s why our platform focuses mainly on stablecoins, making the process smoother and more reliable for everyone.",
      },
      {
        question: "What are the limits ",
        answer:
          "Social Saver limits transfers to €280 per user every week. This allows us to keep transactions smooth and ensure quality service for everyone.",
      },
      {
        question: "How does it work",
        answer:
          "It’s simple! Social Saver groups small USDT transactions into a single €280 batch, which helps cut down on fees. After you deposit USDT, Social Saver transfers €280 to your Revtag, and then you release the USDT to the buyer’s wallet.",
      },
      {
        question: "Can I cancel an order",
        answer:
          "Yes, you can cancel an order as long as it hasn’t been completed. Just go to your account settings or order history, select the order, and choose Cancel.",
      },
      {
        question: "Can I withdraw funds",
        answer:
          "Absolutely. You can withdraw your funds to any linked wallet or bank account at any time. Check with your wallet provider for any specific withdrawal times or fees.",
      },
      {
        question: "Have more questions",
        answer:
          "Feel free to reach out at contact@social-saver.com for further assistance.",
      },
    ],
  },

  testimonial: {
    heading: {
      title: "🌐 Like 1200+ Savers does",
      hilight: "Put the TX fee in your pocket",
    },
    reviews: [
      {
        name: "Michael R.",
        title: "🇨🇦",
        content:
          "Social Saver has been a game-changer for my income—earning has been easy, and the transaction speed is impressive! My only feedback would be to have the 280 order set automatically every week. That feature would make it truly unbeatable!",
        image: "https://placehold.co/120",
      },
      {
        name: "Marie P.",
        title: "🇬🇷",
        content:
          "Social Saver is super easy to use! I made my first transfer in less than 15 minutes. Now, I’m saving on fees and making extra money every week just by sharing with friends.",
        image: "https://placehold.co/120",
      },
      {
        name: "Jamal T.",
        title: "🇬🇧",
        content:
          "I didn’t expect to make this much! Every referral brings in an extra $10, and the steps are so simple that I had no issues getting started. Best side income I’ve found!",
        image: "https://placehold.co/120",
      },
      {
        name: "Sarah L.",
        title: "🇺🇸",
        content:
          "The app is so straightforward. I barely had to figure anything out—just a few clicks, and I’m making money! With a few friends on board, my monthly earnings keep growing.",
        image: "https://placehold.co/120",
      },
      {
        name: "Lucas W.",
        title: "🇩🇰",
        content:
          "“Social Saver turned saving into a passive income stream. And the affiliate bonus is a huge plus! Simple to use, quick to set up, and the extra cash makes it all worth it.",
        image: "https://placehold.co/120",
      },
    ],
    outro: {
      title: "🕢 Still Reading ?",
      content:
        "We process **1 transfer per week** per user, so the sooner you start, the sooner you can get in line for your next one.",
      cta: "CTA TEXT HERE",
    },
  },
};

export default landingEN;
