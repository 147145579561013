"use client";
import { type PropsWithChildren } from "react";
import { NextIntlClientProvider } from "next-intl";
import { Loader } from "@/components/ui/loader";
import getDefaultMessages from "@/messages";
import { Toaster } from "sonner";
import { defaultLocale } from "@/navigation";
import { SessionProvider } from "next-auth/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary } from "@/components/utils/ErrorBoundaries";

const queryClient = new QueryClient();

export const Providers = ({
  children,
  locale = defaultLocale as "en",
}: PropsWithChildren<{
  locale: "en" | "es" | "it" | "ro";
  authGuard?: boolean;
}>) => {
  const messages = getDefaultMessages(locale);
  const isDev = process.env.NODE_ENV === "development";

  if (!isDev) {
    // eslint-disable-next-line no-console
    if (typeof window !== "undefined")
      window.console.error = () =>
        // eslint-disable-next-line no-console
        console.info("Error are disabled in production");
  }
  return (
    <ErrorBoundary>
      <NextIntlClientProvider
        timeZone="Europe/Paris"
        messages={messages}
        locale={locale}
      >
        <SessionProvider>
          <QueryClientProvider client={queryClient}>
            <div
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              className={`fixed left-0 top-0 flex size-full flex-col transition-all duration-700 ${messages ? "-z-10 opacity-0 blur-sm" : "opacity-100"}`}
            >
              <div className="relative m-auto size-1/2">
                <div className="asbolute left-0 top-0 flex size-full flex-col">
                  <Loader
                    size={"77px"}
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    className={`m-auto transition-all duration-700 ${messages ? "size-0" : "opacity-30"}`}
                  />
                </div>
              </div>
            </div>

            {children}

            <Toaster />
            {/* <DialogRenderer /> */}
          </QueryClientProvider>
        </SessionProvider>
      </NextIntlClientProvider>
    </ErrorBoundary>
  );
};
